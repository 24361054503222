<template>
  <div class="guide">
    <div class="mine-title">{{ $t('route.guide') }}</div>
    <div class="cont">
      <van-collapse v-model="activeName" accordion>
        <van-collapse-item :title="item.title" v-for="(item, index) in list" :key="index">
          <template #title>
            <div>{{ item.title }} <van-tag round size="medium" type="primary">{{ item.tag }}</van-tag></div>
          </template>
          <div class="html-text" v-html="item.content"></div>
        </van-collapse-item>
      </van-collapse>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Guide',
  data() {
    return {
      activeName: '1',
      list: []
    }
  },
  mounted() {
    this.$http.get(this.APIURL.PUBLIC.GUIDE).then((res) => {
      this.list = res.data
    });
  }
}
</script>
<style lang="scss" scoped>
.guide {
  .cont {
    margin-top: 20px;

    .html-text {
      color: #666666;

      /deep/img {
        width: 100% !important;
        height: auto !important;
      }

      /deep/ p {
        line-height: 2;
        margin-bottom: 16px;

        img {
          width: 100% !important;
          height: auto !important;
        }

        span {
          font-size: 16px !important;
          font-family: "SourceHanSans", sans-serif !important;

          a {
            color: $color;
          }

          img {
            width: 100% !important;
            height: auto !important;
          }
        }
      }
    }
  }
}
</style>